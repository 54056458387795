var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_c('app-i18n',{attrs:{"code":"home.menu"}})],1),_c('el-breadcrumb-item',[_c('app-i18n',{attrs:{"code":"auth.profile.title"}})],1)],1),_c('div',{staticClass:"app-content-page"},[_c('h1',{staticClass:"app-content-title"},[_c('app-i18n',{attrs:{"code":"auth.profile.title"}})],1),_c('el-form',{ref:"form",staticClass:"form",attrs:{"label-position":_vm.labelPosition,"label-width":_vm.labelWidthForm,"model":_vm.model,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.doSubmit.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"label":_vm.fields.firstName.label,"prop":_vm.fields.firstName.name,"required":_vm.fields.firstName.required}},[_c('el-col',{attrs:{"lg":11,"md":16,"sm":24}},[_c('el-input',{ref:"focus",model:{value:(
              _vm.model[_vm.fields.firstName.name]
            ),callback:function ($$v) {_vm.$set(_vm.model, _vm.fields.firstName.name, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n              model[fields.firstName.name]\n            "}})],1)],1),_c('el-form-item',{attrs:{"label":_vm.fields.lastName.label,"prop":_vm.fields.lastName.name,"required":_vm.fields.lastName.required}},[_c('el-col',{attrs:{"lg":11,"md":16,"sm":24}},[_c('el-input',{model:{value:(
              _vm.model[_vm.fields.lastName.name]
            ),callback:function ($$v) {_vm.$set(_vm.model, _vm.fields.lastName.name, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n              model[fields.lastName.name]\n            "}})],1)],1),_c('el-form-item',{attrs:{"label":_vm.fields.phoneNumber.label,"prop":_vm.fields.phoneNumber.name,"required":_vm.fields.phoneNumber.required}},[_c('el-col',{attrs:{"lg":11,"md":16,"sm":24}},[_c('el-input',{attrs:{"prefix-icon":"el-icon-fa-plus"},model:{value:(
              _vm.model[_vm.fields.phoneNumber.name]
            ),callback:function ($$v) {_vm.$set(_vm.model, _vm.fields.phoneNumber.name, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n              model[fields.phoneNumber.name]\n            "}})],1)],1),_c('el-form-item',{attrs:{"label":_vm.fields.avatarsProfile.label,"prop":_vm.fields.avatarsProfile.name,"required":_vm.fields.avatarsProfile.required}},[_c('el-col',{attrs:{"lg":11,"md":16,"sm":24}},[_c('app-image-upload',{attrs:{"max":_vm.fields.avatarsProfile.max,"path":_vm.fields.avatarsProfile.path(
                _vm.currentUser.authenticationUid
              ),"schema":_vm.fields.avatarsProfile.fileSchema},model:{value:(_vm.model[_vm.fields.avatarsProfile.name]),callback:function ($$v) {_vm.$set(_vm.model, _vm.fields.avatarsProfile.name, $$v)},expression:"model[fields.avatarsProfile.name]"}})],1)],1),_c('el-form-item',[_c('div',{staticClass:"form-buttons"},[_c('el-button',{attrs:{"disabled":_vm.saveLoading,"icon":"el-icon-fa-floppy-o","type":"primary"},on:{"click":_vm.doSubmit}},[_c('app-i18n',{attrs:{"code":"common.save"}})],1),_c('el-button',{attrs:{"disabled":_vm.saveLoading,"icon":"el-icon-fa-undo"},on:{"click":_vm.doReset}},[_c('app-i18n',{attrs:{"code":"common.reset"}})],1),_c('router-link',{attrs:{"to":{ path: '/' }}},[_c('el-button',{attrs:{"disabled":_vm.saveLoading,"icon":"el-icon-fa-close"}},[_c('app-i18n',{attrs:{"code":"common.cancel"}})],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }